import { ContentAreaField } from '../api';
import {
  ArticleCtaFragment,
  ArticleFactBoxFragment,
  ArticleImageFragment,
  ArticleIntroFragment,
  ArticlePagePlugFragment,
  ArticleQuoteFragment,
  ArticleTextFragment,
  ArticleVideoFragment,
  CategoryListBlockFragment,
  ExploreAreaArticleBlockFragment,
  ExploreAreaFragment,
  ExploreAreaNewsBlockFragment,
  ExploreAreaStoriesBlockFragment,
  FormBlockFragment,
  HeroBlockFragment,
  IconListBlockFragment,
  IconPagePlugFragment,
  ListBlockFragment,
  NewsPagePlugFragment,
  OfficesBlockFragment,
  PersonellListFragment,
  RelatedProductsBlockFragment,
  SliderBlockFragment,
  StoryPlugFragment,
  TextBlockFragment,
  VisibleSeoBlockFragment,
} from '../dato';

export type ExploreAreaBlockType =
  | ExploreAreaArticleBlockFragment
  | ExploreAreaNewsBlockFragment
  | ExploreAreaStoriesBlockFragment;

export type ExploreListType =
  | ArticlePagePlugFragment
  | NewsPagePlugFragment
  | StoryPlugFragment
  | IconPagePlugFragment;

export function isArticleCta(
  item: ContentAreaField,
): item is ArticleCtaFragment {
  return item.__typename === 'ArticleCtaRecord';
}

export function isArticleFactBox(
  item: ContentAreaField,
): item is ArticleFactBoxFragment {
  return item.__typename === 'ArticleFactBoxRecord';
}
export function isArticleImage(
  item: ContentAreaField,
): item is ArticleImageFragment {
  return item.__typename === 'ArticleImageRecord';
}
export function isArticleIntro(
  item: ContentAreaField,
): item is ArticleIntroFragment {
  return item.__typename === 'ArticleIntroRecord';
}
export function isArticleQuote(
  item: ContentAreaField,
): item is ArticleQuoteFragment {
  return item.__typename === 'ArticleQuoteRecord';
}
export function isArticleTextBlock(
  item: ContentAreaField,
): item is ArticleTextFragment {
  return item.__typename === 'ArticleTextRecord';
}
export function isArticleVideo(
  item: ContentAreaField,
): item is ArticleVideoFragment {
  return item.__typename === 'ArticleVideoRecord';
}
export function isCategoryListBlock(
  item: ContentAreaField,
): item is CategoryListBlockFragment {
  return item.__typename === 'CategoryListBlockRecord';
}
export function isExploreAreaArticleBlock(
  item: ContentAreaField,
): item is ExploreAreaArticleBlockFragment {
  return item.__typename === 'ExploreAreaArticleBlockRecord';
}
export function isExploreAreaBlock(
  item: ContentAreaField,
): item is ExploreAreaFragment {
  return item.__typename === 'ExploreAreaRecord';
}
export function isExploreAreaNewsBlock(
  item: ContentAreaField,
): item is ExploreAreaNewsBlockFragment {
  return item.__typename === 'ExploreAreaNewsBlockRecord';
}
export function isOfficesBlock(
  item: ContentAreaField,
): item is OfficesBlockFragment {
  return item.__typename === 'OfficesBlockRecord';
}
export function isExploreAreaStoriesBlock(
  item: ContentAreaField,
): item is ExploreAreaStoriesBlockFragment {
  return item.__typename === 'ExploreAreaStoriesBlockRecord';
}
export function isFormBlock(item: ContentAreaField): item is FormBlockFragment {
  return item.__typename === 'FormBlockRecord';
}
export function isHeroBlock(item: ContentAreaField): item is HeroBlockFragment {
  return item.__typename === 'HeroBlockRecord';
}
export function isIconListBlock(
  item: ContentAreaField,
): item is IconListBlockFragment {
  return item.__typename === 'IconListBlockRecord';
}
export function isListBlock(item: ContentAreaField): item is ListBlockFragment {
  return item.__typename === 'ListBlockRecord';
}
export function isPersonellList(item: ContentAreaField): item is PersonellListFragment {
  return item.__typename === 'PersonellListRecord';
}
export function isRelatedProductsBlock(
  item: ContentAreaField,
): item is RelatedProductsBlockFragment {
  return item.__typename === 'RelatedProductsBlockRecord';
}
export function isSliderBlock(
  item: ContentAreaField,
): item is SliderBlockFragment {
  return item.__typename === 'SliderBlockRecord';
}
export function isTextBlock(item: ContentAreaField): item is TextBlockFragment {
  return item.__typename === 'TextBlockRecord';
}
export function isVisibleSeoBlock(
  item: ContentAreaField,
): item is VisibleSeoBlockFragment {
  return item.__typename === 'VisibleSeoBlockRecord';
}
