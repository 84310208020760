export const ALL_ARTICLES_PAGE_SIZE = 24;
export const ALL_ARTICLES_EXCLUDE_TAG_ID = '57680980';
export const PRODUCTS_PAGE_SIZE = 24;
export const RELATED_PRODUCTS_PAGE_SIZE = 4;
export const SEARCH_BOX_PAGE_SIZE = 6;
export const SEARCH_PAGE_SIZE = 24;
export const NOTFOUND_PAGE_SIZE = 3;
export const PRODUCT_LIST_ID = 23;

export const TIMED_DIALOG_KEY = 'timed_dialog_renew_date';
export const RENEW_INTERVAL = 6 * 30 * 24 * 3600 * 1000; // 6 MONTHS
